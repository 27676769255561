.phoneIcon {
    height: 20px;
}

.bg-rug-10 {
    background: red;
}

.bg-rug-20 {
    background: green;
}

.bg-rug-30 {
    background: blue;
}

.bg-rug-40 {
    background: violet;
}